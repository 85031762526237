body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: white;
  margin: 0;
  font-family: 'Raleway', sans-serif;
}

.get-involved__body {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  text-align: center;
  height: 100vh;
  color: darkgreen;
  padding-top: 1px;
}

.get-involved__body h2 {
  display: block;
  font-size: 30px;
  font-weight: 600;
  color: darkgreen;
  margin: 15px 0;
}

.search-form {
  margin: 20px 0;
}

.search-form input, .search-form select {
  margin-right: 10px;
  padding: 8px;
}

.search-form button {
  padding: 8px 16px;
  background-color: darkgreen;
  color: white;
  border: none;
  cursor: pointer;
}

.map-container {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}
