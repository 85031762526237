/* SignUpModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  box-sizing: border-box;
}

.cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: grey;
}

/* Media query for smaller screens */
@media (max-width: 480px) {
  .modal-content {
    width: 90%;
    padding: 15px;
  }

  .cancel-button {
    font-size: 18px;
  }
}
