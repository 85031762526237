@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

/* NavBar.css */
.navbar__container {
  display: flex;
  justify-content: space-between; /* Ensure title is on the left and links on the right */
  align-items: center;
  font-family: 'Inter', sans-serif; /* Apply the font family */
  color: white; /* Set the color to dark green */
  position: fixed; /* Set position to fixed */
  z-index: 1000;
  width: 100%; /* Ensure the navbar spans the full width */
  position: fixed;
  width: 98%;
  box-sizing: border-box;
}

.navbar__green {
  color: darkgreen; /* Ensure text is white on green background */
}

.navbar__container-logo {
  padding-left: 50px; /* Adjust left padding */
}

.navbar__container-logo img {
  width: 150px; /* Adjust logo width */
}

.navbar__scrolled {
  background: rgba(0, 0, 0, 0.5); /* Overlay background color when scrolled */
  color: white; /* Ensure text color is white */
  width: 100%;
}

.navbar__container-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 25px; /* Adjust gap between links */
  font-size: 1.1em;
  justify-content: flex-end; /* Align links to the right */
  margin-right: 25px; /* Add margin to separate links from the right edge */
  font-weight: 600;
  margin-top: -35px; /* Move links a bit higher */
  text-transform: uppercase;
  width: 100vw;
  align-items: center;
}

.navbar__container-links li {
  margin: 0;
  margin-right: 15px;
}

.navbar__container-links a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  font-size: 1em; /* Set a consistent font size */
}

.navbar__container-links a:hover {
  color: #9b9393; /* Change color on hover */
}

@media (max-width: 768px) {
  @media (max-width: 800px) {
    .navbar__container {
      flex-direction: column;
      align-items: center; /* Center align the items */
    }
  
    .navbar__container-logo img {
      display: none; /* Hide the logo */
    }
  
    .navbar__container-links {
      font-size: 0.8em; /* Further reduce font size for links */
      justify-content: right; /* Center align the links */
      padding-top: 50px;
      padding-left: 25px;
    }
  }
}

@media (max-width: 400px) {
  .navbar__container {
    padding: 0 0px;
  }

  .navbar__container-links {
    gap: 10px;
    text-align: center;
  }
}