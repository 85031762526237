@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

/* Footer Container */
.footer__container {
  background-color: #293827; 
  color: #fff; 
  width: 100%;
  padding-bottom: 20px; /* Add padding to the bottom */
}

.footer__container li {
  list-style-type: none; /* Remove default list-style */
}

/* Footer Logo Text */
.footer__logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Libre Baskerville', sans-serif;
  text-decoration: none;
  color: #fff;
  list-style-type: none; /* Remove default list-style */
  margin-left: 30px;
}

.footer__content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
  /* Footer Links */
  .footer__links {
    display: flex;
    justify-content: right;
    margin-left: 30px;
    line-height: 2;
    flex-wrap: wrap;
  }
  
  .footer__heading {
    font-size: 1.1rem; 
    font-weight: 700;
    margin-right: 50px; /* Adjust margin-right */
    padding: 0;
    margin-bottom: 0;
    margin-top: 30px;
  }
  
  .footer__list {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
  }
  
  .footer__link {
    color: #ccccccab;
    text-decoration: none;
    font-weight: 400;
  }
  
  .footer__link:hover {
    color: #fff;
  }
  
  /* Footer Divider */
  .footer__divider {
    border-top: 1px solid #333; 
    width: 80%; 
  }
  
/* Footer Copyright */
.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: left; /* Center content horizontally */
  margin-left: 200px;
}

.footer__copyright-text {
  font-size: 0.9rem;
  color: #ccc;
  padding: 10px;
}

.footer__copyright-link {
  color: #ccc;
  text-decoration: none;
}

.footer__copyright-link:hover {
  color: #fff;
}

@media (max-width: 800px) {
  .footer__logo-text {
    display: none; /* Hide the title on small screens */
  }

  .footer__content {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
  }

  .footer__copyright {
    align-items: center;
    margin-left: 0;
  }
}