.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 50px auto;
  max-width: 80%;
  height: auto;
  background-color: white;
  flex-wrap: wrap;
}

.contact-text-container {
  flex: 1 1 300px;
  display: flex;
  justify-content: space-evenly;
  background: white;
  padding-right: 4rem;
}

.contact-text {
  font-size: 1.8em; /* Reduce font size for smaller screens */
  text-align: center;
  color: darkgreen;
  font-family: 'Raleway', sans-serif;
  margin-top: 20px;
  max-width: 90%; /* Limit width for smaller screens */
}

.form {
  flex: 1 1 400px;
  max-width: 600px;
  background: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #000;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  position: relative;
  color: darkgreen;
  overflow: hidden;
}

.form h2 {
  padding-bottom: 10px;
  color: darkgreen;
  border-bottom: 3px solid darkgreen;
}

.form p {
  margin: 20px 0;
}

.form label {
  display: block;
  font-size: 16px;
  color: #5a5a5a;
  text-align: left;
  margin-bottom: 5px;
}

.form input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  font-family: 'Raleway', sans-serif;
  transition: all .3s;
  border-bottom: 2px solid #bebed2;
  color: darkgreen;
}

.form input:focus {
  border-bottom: 2px solid darkgreen;
}

.form button {
  float: right;
  padding: 8px 12px;
  margin: 8px 0 0;
  font-family: 'Raleway', sans-serif;
  border: 2px solid darkgreen;
  background: 0;
  color: darkgreen;
  cursor: pointer;
  transition: all .3s;
}

.form button:hover {
  background: darkgreen;
  color: #fff;
}


@media (max-width: 768px) {
  .contact-text {
    font-size: 1.2em; /* Further reduce font size for screens smaller than 768px */
  }
  .content-wrapper {
    margin: 10px auto;
  }
  .contact-text {
    font-size: 1em;
  }
  .form iframe {
    height: 500px;
  }
}
