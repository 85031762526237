@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

frame {
  width: 90%;
  margin: 10px auto;
  text-align: center;
}

button {
  margin: 10px;
}

.custom-btn {
  width: 130px;
  height: 30px; /* Increased height for better visibility */
  color: #143207;
  border-radius: 5px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-flex; /* Changed to inline-flex for better centering */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  outline: none;
}

.btn-3 {
  background: #a7c899;
  width: 130px;
  height: 30px;
  padding: 0;
  border: none;
}

.btn-3 span {
  position: relative;
  display: inline-block; /* Ensures span takes up its own block */
  width: 100%;
  text-align: center; /* Centers text within the span */
}

.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: rgb(33, 56, 33);
  transition: all 0.3s ease;
}

.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  background: rgb(33, 56, 33);
  transition: all 0.3s ease;
}

.btn-3:before {
  height: 0%;
  width: 2px;
  bottom: 0;
}

.btn-3:after {
  width: 0%;
  height: 2px;
  left: 0;
}

.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: rgb(90, 116, 90);
  transition: all 0.3s ease;
}

.btn-3 span:before {
  width: 2px;
  height: 0%;
}

.btn-3 span:after {
  width: 0%;
  height: 2px;
}

@media (max-width: 768px) {
  .custom-btn {
    font-size: 0.9em; /* Adjusted font size */
    width: 60%; /* Adjusted width for smaller screens */
    height: 35px; /* Adjusted height for smaller screens */
  }

  .btn-3 {
    width: 60%; /* Adjusted width for smaller screens */
    height: 35px; /* Adjusted height for smaller screens */
  }
}